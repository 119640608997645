import React, { useState, useEffect } from 'react';
 
import carre from '../svg/Carre.svg';
import rond from '../svg/Rond.svg';
import losange from '../svg/Losange.svg'; 

function IconRank (props){
    
  const [icon,setIcon] = useState(carre);  
 
  const [level, setLevel] = useState(props.level);
  
  useEffect(
    
    () => { 
      setLevel(props.level);
      if (props.level<4){setIcon(carre);}
      else if(props.level>3 && props.level<7){setIcon(rond);}     
      else if (props.level>6){setIcon(losange);}       
    
    }

  
  );

   
 

  return ( 
    <div className="icon">

      <img src={icon} alt="icon rank"/> 
        
      <p width="80" height="80">{level}</p> 
        
    </div>
    );
  } 
  export default IconRank;