
import design from './png/design.png';
import './App.css'; 
import './css/style-bootstrap.css';
import './css/style-scss.css';
import Levelxp from './class/Levelxp'; 
import Legend from './class/Legend';
 


function App() {

 
  return (
    <div className="App container-fluid">  

      <Levelxp></Levelxp>

          
      <h2>Ranks</h2>
     
      
     
      <Legend></Legend>
      
       
    </div>
  );
}

export default App;
