import React, { useState, useEffect } from 'react';
  
function Actions (props){ 

  useEffect(
    
    () => {  
  
    } 
  
  );  

  function eventAction1(){
    console.log("eventAction1");

  }

  
  function eventAction2(){
    console.log("eventAction2");
    
}
 
   
  return ( 
    
    <div className='row'>  
    
    
           <div className='col card'>
   
           <h3>+ Xp :</h3>
   
                
                <p><button type="button" className="btn btn-primary mw-50"  onClick={eventAction1}>Event 1</button> </p>
                <p><button type="button" className="btn btn-primary mw-50"  onClick={eventAction1}>Event 2</button> </p>
                <p><button type="button" className="btn btn-primary mw-50"  onClick={eventAction1}>Event 3</button> </p>
               
               
           </div>
   
           
           <div className='col card'>
               
           <h3>- Xp :  </h3>
    
                 <p><button type="button" className="btn btn-primary mw-50"  onClick={eventAction2}>Event 1</button> </p>
                 <p><button type="button" className="btn btn-primary mw-50"  onClick={eventAction2}>Event 2</button></p> 
                 <p><button type="button" className="btn btn-primary mw-50"  onClick={eventAction2}>Event 3</button> </p>
                
           
           </div>
    
   
           
    </div>
       
    
    
    );
};
  export default Actions;