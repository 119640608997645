import React, { useState, useEffect } from 'react';
 
import carre from '../svg/Carre.svg';
import rond from '../svg/Rond.svg';
import losange from '../svg/Losange.svg'; 

function Legend (){ 

    const [width, setWitdh]=useState(30);
    
    const [rank1, setRank1]=useState("Rank1");
    const [rank2, setRank2]=useState("Rank2");
    const [rank3, setRank3]=useState("Rank3"); 

  useEffect(
    
    () => { 
    
    
    }

  
  );

   
 

  return ( 
     

<div className="legend row align-items-start">
    <div className="col card p-2 m-2">
    <h3>{rank1}</h3>
    <p><img src={carre}  width={width + "px"} alt="icon rank"/></p>
    
    
    </div>
    <div className="col card p-2 m-2">
    <h3>{rank2}</h3>    
    <p><img src={rond} width={width + "px"} alt="icon rank"/></p>
     


    </div>
    <div className="col card p-2 m-2">
    <h3>{rank3}</h3>
    <p><img src={losange} width={width + "px"}  alt="icon rank"/></p>
   
    </div>
  </div> 

        
    
    );
  } 
  export default Legend;