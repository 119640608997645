import React, { useState, useEffect } from 'react';
import Actions from './Actions';
import BarXp from './BarXp';
import IconRank from './IconRank'; 
 
 
function Levelxp (){
    
  const [height, setHeight] = useState(85);  

  const [xp, setXp] = useState(2785); 

  const [eventAnim, setEventAnim] = useState("");
   
  const [up, setUp] = useState(250);   
  const [down, setDown] = useState(150); 
  
  const [eventValue, setEventValue] = useState("");

  const [level, setLevel] = useState(0);
  const [levelMax, setLevelMax] = useState(9);

  const [min, setMin] = useState(0); 
  const [max, setMax] = useState( 2500);
  
  const [reste,setReste] = useState(0);
  
  useEffect(
    
    () => { 

    
    if(xp>=max && level < levelMax){

      setLevel(level+1); 
      setReste(xp-max);     
      setXp(xp-max);      
 
    } 
    if(xp<min  && level  > 0){

      setLevel(level-1); 
      setReste(min - xp);     
      setXp(max-(min - xp));
      
    }     
    else( setReste(0))

    console.log( " [XP : " + xp + "; Level : " + level + " ; Reste : "+ reste +"]");
  
    } 
  
  ); 
  
  function augmenterXp(){ 

    setEventAnim('anim');

    if(level <= levelMax){
     
    setUp((Math.floor(Math.random()*100))*10);
    console.log( "+" + up);
    
    setXp(
       xp +  up
      ); 
      
    setEventValue(`+${up}`); 

    } 
 
  }

  function diminuerXp(){ 

    setEventAnim('anim');
    
    if(level >= 0 ){
     
    setDown((Math.floor(Math.random()*100))*10);
    console.log( "-" + down);

    setXp(
       xp -  down
      );   
    }
    setEventValue(`-${down}`); 

  }

  return ( 
    
 <div>  

      <Actions></Actions>
       
      <div id="rank" className="row text-start align-items-center">

       

        <div className="col-6  col-sm-1 order-2 order-sm-0 text-center my-4">
            <button type="button" className="btn btn-primary mw-50"  onClick={diminuerXp}>-</button>
        </div>
        
        <div className="col-sm-2 order-0 order-sm-1"> 

          <IconRank level={level}></IconRank>  

        </div>



        <div className="col-sm-8 order-1 order-sm-2">

        <BarXp level={level} xp={xp} max={max} height={height} 
        eventAnim={eventAnim} eventValue={eventValue}></BarXp> 
 
        </div>

        <div className="col-6 col-sm-1 order-3 order-sm-3 text-center my-4">
            <button type="button" className="btn btn-primary mw-50"  onClick={augmenterXp}>+</button>

        </div>
      </div> 
   
    

     

  </div>
  
 
    );
  } 
  export default Levelxp;