import React, { useState, useEffect } from 'react';
 
function BarXp (props){
     
  
  const [height, setHeight] = useState();  

  const [xp, setXp] = useState(); 

  const [eventAnim, setEventAnim] = useState();
 
  const [eventValue, setEventValue] = useState();

  const [level, setLevel] = useState();
  const [levelMax, setLevelMax] = useState();

  const [min, setMin] = useState(); 
  const [max, setMax] = useState();
   
  
  useEffect(
    
    () => { 

    setXp(props.xp)

    setHeight(props.height)
    setEventAnim(props.eventAnim);
    setEventValue(props.eventValue);

    setLevel(props.level)
    setLevelMax(props.levelMax);

    setMin(props.min);
    setMax(props.max);
     
  
    } 
  
  );  
   
 

  return ( 
   
<div className="col-sm-8 order-1 order-sm-2">

<p>RANK</p>
<p>Level {level}</p> 

<div className="barrexp">

  <p id="eventValue"><span  className={eventAnim}>{eventValue}</span></p>

  <svg viewBox={`0 0  ${max}  ${height}`}  xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" fill="#F5F5F5" width={max} height={height} />  
    <rect className="xp" x="0" y="0" width={xp} height={height} /> 
  </svg>

  <p>{xp} / {max} xp </p>

</div> 

</div>
    );
  } 
  export default BarXp;
